import React, { useEffect, useState } from "react";
import { enfermeroResource, getAuthUser, getEntidades, usuariosResource } from "../../helpers/configRoutes/backend_helper";
import Pagos from "../../components/registro/enfermero/Pagos";
import * as Yup from "yup";
import { useFormik } from "formik";
import { validaClabe, validaNames } from "../../helpers/formularios/Validation";
import CustomSelect from "../../components/inputs/CustomSelect";
import CustomInput from "../../components/inputs/CustomInput";
import CustomButtonOval from "../../components/buttons/CustomButtonOval";
import { setUser } from "../../store/auth/login/action";
import { useDispatch, useSelector } from "react-redux";

const InformacionFacturacion = () => {
    const authUser = useSelector((state)=>state.Login.user)
    const [editar, setEditar] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: authUser.bancarios,
        validationSchema: Yup.object({
            cuenta_nombre: validaNames(),
            clabe: validaClabe(),
            cuenta_no: Yup.string()
                .required('Campo requerido')
                .matches(/^\d{5,10}$/, 'El numero de cuenta debe tener entre 5 y 10 dígitos'),
            rfc: Yup.string()
                .required('Campo requerido'),
            entidad_id: Yup.number()
                .required('La entidad es requerida')
                .integer('La entidad debe ser un número entero')
                .positive('La entidad debe ser un número positivo'),
        }),
        onSubmit: async (values, { setErrors }) => {
            setLoading(true)
            authUser.bancariosDatos = true
            authUser.bancarios = values
            const response = await usuariosResource('put',authUser,authUser.id)
            if(response.status){
                setEditar(false)
                dispatch(setUser(response.item))
            }
            setLoading(false)
        },
    });

    const [bancarias,setBancarias] = useState([])
    
    useEffect(() => {
        const getCatalogos = async () => {
            const response = await getEntidades()
            setBancarias(response.entidadesBancarias)
        }
        getCatalogos()
    }, []);

    return (
        <div className="w-full px-4 ml-[18%] max-w-[78%]">
            <div className="flex flex-col md:flex-row w-full p-6 bg-[#f9f5f3] rounded-lg shadow-md">
                {/* Cuenta de pago */}
                <div className="w-full md:w-1/2 p-4 border-r border-gray-300">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-bold">Cuenta de pago</h2>
                        {!editar &&
                            <button className="px-3 py-1 text-sm bg-[#e4d8d2] rounded-md" onClick={() => setEditar(!editar)}>Editar datos</button>
                        }
                    </div>
                    {editar ?
                        <>
                            <form onSubmit={e => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                            >
                                <div className="flex flex-wrap max-w-[80%] ml-[19%] mt-[60px]">
                                    <div className="w-full text-base font-bold leading-6 max-md:max-w-full">
                                        Cuenta de pago de honorarios
                                    </div>
                                    <div className="w-full px-2 mb-3 border-t border-black border-solid"></div>
                                    <div className="w-full md:w-1/2 px-2 mb-3">
                                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                            RFC
                                        </div>
                                        <CustomInput
                                            name="rfc"
                                            type={'text'}
                                            placeholder={'RFC'}
                                            value={validation.values?.rfc || ''}
                                            error={validation.errors?.rfc}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                        />
                                    </div>
                                    <div className="w-full md:w-1/2 px-2 mb-3">
                                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                            Nombre de la cuenta
                                        </div>
                                        <CustomInput
                                            name="cuenta_nombre"
                                            type={'text'}
                                            placeholder={'Nombre'}
                                            value={validation.values?.cuenta_nombre || ''}
                                            error={validation.errors?.cuenta_nombre}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                        />
                                    </div>
                                    <div className="w-full md:w-1/2 px-2 mb-3">
                                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                            CLABE
                                        </div>
                                        <CustomInput
                                            name="clabe"
                                            type={'text'}
                                            placeholder={'CLABE'}
                                            value={validation.values?.clabe || ''}
                                            error={validation.errors?.clabe}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                        />
                                    </div>
                                    <div className="w-full md:w-1/2 px-2 mb-3">
                                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                            No. de cuenta
                                        </div>
                                        <CustomInput
                                            name="cuenta_no"
                                            type={'number'}
                                            placeholder={'No. Cuenta'}
                                            value={validation.values?.cuenta_no || ''}
                                            error={validation.errors?.cuenta_no}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                        />
                                    </div>
                                    <div className="w-full md:w-1/2 px-2 mb-3">
                                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                            Entidad bancaria{' '}<span className='text-red-600'>*</span>
                                        </div>
                                        <CustomSelect
                                            name="entidad_id"
                                            options={[
                                                { label: 'Seleccione una opción.', value: 0 },
                                                ...bancarias?.map(data => {
                                                    return { value: data?.id, label: data?.nombre }
                                                })
                                            ]}
                                            value={validation.values?.entidad_id || ''}
                                            error={validation.errors?.entidad_id}
                                            onChange={validation.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full px-2 mb-3 border-t border-black border-solid"></div>
                                <div className="flex gap-3 text-2xl font-bold text-black max-md:flex-wrap mt-10 ">
                                    <CustomButtonOval
                                        loading={loading}
                                        textCarga={'Cancelar'}
                                        className="grow justify-center items-center shadow-sm bg-stone-100 rounded-[36px] w-fit max-md:px-5 max-md:max-w-full hover:bg-o2o-gris-hover"
                                        onClick={() => {
                                            setEditar(!editar)
                                        }}
                                    >
                                        Cancelar
                                    </CustomButtonOval>
                                    <CustomButtonOval 
                                    loading={loading}
                                    textCarga={'Guardando datos'} 
                                    className="grow justify-center  bg-o2o-aqua shadow-sm rounded-[36px] w-fit max-md:px-8 max-md:max-w-full hover:bg-o2o-aqua-hover" type="submit">
                                        Guardar
                                    </CustomButtonOval>
                                </div>
                            </form>
                        </>
                        :
                        <>
                            <p><strong>Nombre:</strong> <span className="font-bold">{authUser.bancarios?.cuenta_nombre}</span></p>
                            <p><strong>Número de cuenta:</strong> <span className="font-bold">{authUser.bancarios?.cuenta_no}</span></p>
                            <p><strong>CLABE:</strong> <span className="font-bold">{authUser.bancarios?.clabe}</span></p>
                            <p><strong>Entidad Bancaria:</strong> <span className="font-bold">{authUser.bancarios?.entidad_nombre}</span></p>
                        </>
                    }

                </div>

                {/* Datos para facturación */}
                <div className="w-full md:w-1/2 p-4">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-bold">Datos para facturación</h2>
                        <button className="px-3 py-1 text-sm bg-[#e4d8d2] rounded-md">Copiar datos</button>
                    </div>
                    <p className="text-sm text-gray-600 mb-4">
                        Utiliza estos datos para realizar tu recibo de honorarios. Recuerda que el concepto es:
                        «servicios médicos y enfermería».
                    </p>
                    <div className="grid grid-cols-2 gap-2 text-sm">
                        <p><strong>Nombre:</strong> Proveedora de Productos y Servicios Roxima Sa de CV</p>
                        <p><strong>RFC:</strong> LEOA123456AA7</p>
                        <p><strong>Calle:</strong> Privada de la 23 sur</p>
                        <p><strong>Colonia:</strong> Ex-Hacienda La Noria</p>
                        <p><strong>Número exterior:</strong> 3701</p>
                        <p><strong>Número interior:</strong> B-103</p>
                        <p><strong>Código postal:</strong> 72410</p>
                        <p><strong>Municipio:</strong> Puebla</p>
                        <p><strong>Estado:</strong> Puebla</p>
                        <p><strong>País:</strong> México</p>
                        <p><strong>E-mail:</strong> facturación@domain.com</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InformacionFacturacion;
