import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Divider from './Divider';
import CustomButtonOval from './buttons/CustomButtonOval';
import CustomButtonSquare from './buttons/CustomButtonSquare';

const dias = [
  { id: 1, nombre: 'Domingo' },
  { id: 2, nombre: 'Lunes' },
  { id: 3, nombre: 'Martes' },
  { id: 4, nombre: 'Miércoles' },
  { id: 5, nombre: 'Jueves' },
  { id: 6, nombre: 'Viernes' },
  { id: 7, nombre: 'Sábado' }
];

function getMonthCalendar(year, month) {
  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0);
  const daysInMonth = lastDayOfMonth.getDate();

  const calendar = [];

  // Rellenar los días anteriores al primer día del mes
  for (let i = 0; i < firstDayOfMonth.getDay(); i++) {
    calendar.push({
      day: '',
      dayOfWeek: ''
    });
  }

  // Rellenar los días del mes
  for (let i = 1; i <= daysInMonth; i++) {
    const date = new Date(year, month, i);
    const dayOfWeek = date.toLocaleDateString('es-ES', { weekday: 'short' });
    calendar.push({
      day: i,
      dayOfWeek: dayOfWeek
    });
  }

  return calendar;
}

const Calendar = ({ year, month, diasSeleccionados }) => {
  const monthCalendar = getMonthCalendar(year, month);
  const monthName = new Date(year, month).toLocaleDateString('es-ES', { month: 'long' });
  const dayNamesAbbr = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'];

  let diasMarca = dias?.map(data => {
    if (diasSeleccionados?.includes(data?.id)) {
      return { id: data?.id, nombre: data?.nombre, acronimo: data?.nombre?.substring(0, 3)?.toLowerCase() };
    }
  });
  return (
    <div className="flex flex-col justify-center items-center text-sm">
      <h2 className="text-xl font-bold mb-5">{monthName ? `${monthName} ${year}` : ''}</h2>
      <div className="grid grid-cols-7 gap-2">
        {dayNamesAbbr.map((dayName, index) => (
          <div key={index} className="text-center text-xs text-black font-bold">{dayName}</div>
        ))}
        {monthCalendar.map((dayInfo, index) => {
          let count = diasMarca?.filter(marca => marca?.acronimo === dayInfo?.dayOfWeek).length;
          return (
            <button
              key={index}
              className={` text-xs flex justify-center items-center w-10 h-10 ${dayInfo.day ? `text-black ${count > 0 ? 'bg-o2o-aqua rounded-full' : ''}` : 'bg-gray-100 text-gray-600'
                }`}
            >
              {dayInfo.day}
            </button>
          )
        }
        )}
      </div>
    </div>
  );
}


const CustomCalendar = ({ fechaInicio, fechaFin, setFechaInicio, setFechaFin, diasSeleccionados, setDiasSeleccionados, validation }) => {

  const currentDate = new Date();
  /* Inicializamos con el año actual */
  const [anioInicio, setAnioInicio] = useState(currentDate.getFullYear());
  const [anioFin, setAnioFin] = useState(currentDate.getFullYear());
  // Los meses se numeran del 0 al 11, por lo que sumamos 1 para obtener el mes actual
  const [mesInicio, setMesInicio] = useState(currentDate.getMonth() + 1);
  const [mesFin, setMesFin] = useState(currentDate.getMonth() + 1);

  const next = () => {

  }

  const prev = () => {

  }

  useEffect(() => {

    const inicio = fechaInicio ? new Date(fechaInicio) : null;
    const fin = fechaFin ? new Date(fechaFin) : null;
    setAnioInicio(inicio ? inicio.getFullYear() : currentDate.getFullYear());
    setAnioFin(fin ? fin.getFullYear() : currentDate.getFullYear());
    setMesInicio(inicio ? inicio.getMonth() + 1 : currentDate.getMonth());
    setMesFin(fin ? fin.getMonth() : currentDate.getMonth());

    return () => { }
  }, [fechaInicio, fechaFin])


  const agregarDias = (id) => {
    const diasActuales = validation.values.dias || [];
    //let nuevosDias = [...diasActuales, id];


    let nuevosDias = [];
    if (diasActuales.filter(interes => interes == id).length > 0) {
      nuevosDias = diasActuales.filter(interes => interes !== id)
      validation.setFieldValue('dias', nuevosDias);
    } else {

      nuevosDias = [...diasActuales, id];
      validation.setFieldValue('dias', nuevosDias);

    }

  }

  return (
    <>
      <div className='flex flex-wrap p-2'>
        <div className='w-full mb-5 font-bold'>
          Elige los días que requieres servicio <span className='text-red-600'>*</span>
        </div>
        <div className='w-full md:w-2/3 flex flex-wrap gap-2 mb-5'>
          {dias?.map((dia, index) => (
            <CustomButtonSquare
              type='button'
              key={index}
              className={`self-start hover:bg-o2o-gris-arena ${diasSeleccionados?.includes(dia?.id) ? 'bg-o2o-gris-hover' : ''}`}
              onClick={() => agregarDias(dia?.id)}
            >
              {dia.nombre}
            </CustomButtonSquare>
          ))}
        </div>
        <div className='w-full md:w-1/3 flex flex-col gap-2'>
          <CustomButtonSquare
            className='bg-o2o-gris-arena w-full'
            type='button'
          >
            <p className='font-bold'>Inicia <span className='text-red-600'>*</span></p>
            <p>
              <input
                type="date"
                id="fechaInicio"
                name="fecha_inicio"
                className='bg-o2o-gris-arena w-full'
                value={fechaInicio}
                onChange={validation.handleChange}
              />
            </p>
          </CustomButtonSquare>
          <CustomButtonSquare
            type='button'
            className='bg-o2o-gris-arena w-full'
          >
            <p className='font-bold'>Termina <span className='text-red-600'>*</span></p>
            <p>
              <input
                type="date"
                id="fechaFin"
                name="fecha_fin"
                className='bg-o2o-gris-arena w-full'
                value={fechaFin}
                onChange={validation.handleChange}
              />
            </p>
          </CustomButtonSquare>
        </div>
        <div className='w-full'>
          <Divider />
        </div>
      </div>

      <div className='flex flex-wrap justify-center p-2'>
        <div className='w-full md:w-5/12 mb-4 md:mb-0 md:mr-4'>
          <Calendar year={anioInicio} month={mesInicio - 1} diasSeleccionados={diasSeleccionados} />
        </div>
        <div className='w-full md:w-5/12'>
          <Calendar year={anioFin} month={mesFin} diasSeleccionados={diasSeleccionados} />
        </div>
      </div>
    </>

  );
}

export default CustomCalendar