import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "./components/header/Header";
import Fotter from "./components/fotter/Fotter";
import { Element } from "react-scroll";
import { generalRoutes, publicRoutes, userRoutes, outRoutes } from "./routes/allRoutes";
import { PrivateRoute, LogoutRoute, NotAuthRoute } from "./routes/PrivateRoute";
import { config_zendesk, getAuthUser, getEspecialidades, getTiempos, isUserAuthenticated, reviews_testimoniales } from "./helpers/configRoutes/backend_helper";
import Loading from "./components/Loading";
import Pusher from 'pusher-js';
import { useDispatch } from "react-redux";
import { setContador } from "./store/conversaciones/action";
import { setConfig } from "./store/zendesk/action";
import { setConfigPublic } from "./store/publicConfig/action";


const App = () => {
    const isAuth = isUserAuthenticated();
    const userAuth = getAuthUser()
    const dispatch = useDispatch()
    useEffect(() => {
        const getZendeskConfig = async () => {
            const response = await config_zendesk({})
            dispatch(setConfig(response.confg))
            const responseTestimoniales = await reviews_testimoniales({})
            const responseEspecialidades = await getEspecialidades();
            const responseTurnos = await getTiempos();
            const data = {
                testimoniales_enfermeros:responseTestimoniales.calificacion_enfermero,
                testimoniales_plataforma:responseTestimoniales.calificacion_plataforma,
                calificables_plataforma:responseTestimoniales.calificables_plataforma,
                c_turnos:responseTurnos.turnos,
                c_horarios:responseTurnos.tiempos,
                catEspecialidades:responseEspecialidades.especialidades,
            }
            dispatch(setConfigPublic(data))
        }
        getZendeskConfig()
        if (isAuth) {
            // Configuración de Pusher
            const pusher = new Pusher((process.env.REACT_APP_PUSHER_APP_KEY), {
                cluster: (process.env.REACT_APP_PUSHER_APP_CLUSTER ),
                forceTLS: false,  // Desactiva SSL en localhost
                encrypted: true,
                enabledTransports: ['ws', 'wss'],
            });

            const channel = pusher.subscribe('chat');
            channel.bind('MessageSent', function (data) {
                const toUser = data.message.original.toUser;
                if (userAuth.id == toUser) {
                    const totalSinLeer = data.message.original.totalSinLeer;
                    dispatch(setContador(totalSinLeer))
                }
            });

            return () => {
                pusher.unsubscribe('chat');
            };
        }
    }, []);

    return (
        <>
            <div className="mx-auto py-8">
                <Element name="inicio"></Element>
                <Loading />
                <Router>
                    <Header />
                    <ToastContainer />

                    <Routes>
                        {outRoutes.map((route, index) => (
                            <Route
                                path={route.path}
                                element={<LogoutRoute element={route.element} />}
                                key={route.path}
                            />
                        ))}

                        {publicRoutes.map((route, index) => (
                            <Route
                                path={route.path}
                                element={<NotAuthRoute element={route.element} />}
                                key={route.path}
                            />
                        ))}


                        {userRoutes.map((route, index) => (
                            <Route
                                path={route.path}
                                element={<PrivateRoute element={route.element} menu={route.menu} />}
                                key={route.path}
                            />
                        ))}

                        {generalRoutes.map((route, index) => (
                            <Route
                                path={route.path}
                                element={route.element}
                                key={route.path}
                            />
                        ))}

                    </Routes>
                    <Fotter />

                </Router>
            </div>
        </>
    );
}

export default App;