import React, { useEffect, useState } from "react";
import BaseModalAnimated from "./BaseModalAnimated";
import CustomButtonOval from "../buttons/CustomButtonOval";


const PreviewPDF = ({ modal, setModal }) => {
  const [fileURL, setFileURL] = useState(null);

  useEffect(() => {
    const handleSignedURL = async () => {
      if (modal.firma) {
        try {
          const response = await fetch(modal.url);
          if (!response.ok) {
            throw new Error("Failed to fetch the file.");
          }
          const blob = await response.blob();
          const objectURL = URL.createObjectURL(blob);
          setFileURL(objectURL);
        } catch (error) {
          console.error("Error fetching the signed URL:", error);
          setFileURL(null);
        }
      } else {
        // If not a signed URL, use the direct URL
        setFileURL(modal.url);
      }
    };

    if (modal.url) {
      handleSignedURL();
    }

    // Cleanup object URL when the component unmounts or modal.url changes
    return () => {
      if (fileURL) {
        URL.revokeObjectURL(fileURL);
      }
    };
  }, [modal.url, modal.firma]);

  return (
    <BaseModalAnimated setModal={setModal} modal={modal}>
      <div className="flex flex-col items-center justify-center p-6 rounded-b border-slate-200">
        {/* Previsualización del archivo */}
        {fileURL ? (
          <iframe
            src={fileURL}
            allowFullScreen
            style={{
              overflow: "auto",
              height: "500px",
              width: "100%",
            }}
          ></iframe>
        ) : (
          <p>Loading preview...</p>
        )}

        {/* Botón para cerrar el modal */}
        <CustomButtonOval
          type="button"
          onClick={() => setModal({ ...modal, show: false })}
          className="mt-4 w-full lg:w-[30%] bg-mts-red hover:bg-mts-red-hover"
        >
          Cancel
        </CustomButtonOval>
      </div>
    </BaseModalAnimated>
  );
};

export default PreviewPDF;
