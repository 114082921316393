import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import SaltarPaso from "../SaltarPaso";
import { useDispatch } from "react-redux";
import Facturacion from "./Facturacion";
import { update_usuario } from "../../../store/users/registro/action";
import { getEntidades } from "../../../helpers/configRoutes/backend_helper";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../inputs/CustomSelect";
import CancelarModal from "../CancelarModal";
import { showFormErrors } from "../../../helpers/showErrors";
import { validaRfc } from "../../../helpers/formularios/Validation";

// Cargar la clave pública de Stripe
const stripePromise = loadStripe(process.env.STRIPE_PUBLIC || 'pk_test_51QKsgXJ7bvcjvRG5IFNF8BQSKFqROHTbcFcuPDaBtQ6jqG2UuAKlzPviBLR1y2LbDZgHv9QdipxPWjAJMPHHDEPJ00mmo68qZ4');

const Cobro = ({ setStep, item, setItem }) => {
    const [modal, setModal] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();

    const [bancarias, setBancarias] = useState([]);
    const [financieras, setFinancieras] = useState([]);

    const is_factura = (input) => {
        if (validation.values.is_factura) {
            return input == "str" ?
                Yup.string().required('Campo requerido')
                :
                (
                    input == "email" ?
                        Yup.string()
                            .email('Correo electrónico inválido')
                            .required('Campo requerido')
                        :
                        (input == 'rfc' ?
                            validaRfc()
                            :
                            Yup.number()
                                .typeError('Debe ser un número')
                                .required('Campo requerido')
                                .test('len', 'Debe tener exactamente 5 dígitos', val => val && val.toString().length === 5)
                        )
                )
        } else {
            return Yup.string();
        }
    }
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: item.bancarios,
        validationSchema: Yup.object({
            tarjeta_nombre: Yup.string().required('Campo requerido'),
            entidad_id: Yup.string().required('Campo requerido'),
            entidad_financiera: Yup.string().required('Campo requerido'),
            calle: Yup.lazy(value => {
                return is_factura('str')
            }),
            cp: Yup.lazy(value => {
                return is_factura('str')
            }),
            numeroExterior: Yup.lazy(value => {
                return is_factura('str')
            }),
            numeroInterior: Yup.string(),
            pais: Yup.lazy(value => {
                return is_factura('str')
            }),
            estado: Yup.lazy(value => {
                return is_factura('str')
            }),
            municipio: Yup.lazy(value => {
                return is_factura('str')
            }),
            colonia: Yup.lazy(value => {
                return is_factura('str')
            }),
            razon_social: Yup.lazy(value => {
                return is_factura('str')
            }),
            email: Yup.lazy(value => {
                return is_factura('email')
            }),

            rfc: Yup.lazy(value => {
                return is_factura('rfc')
            }),
            uso_id: Yup.lazy(value => {
                return is_factura('str')
            }),
            regimen_id: Yup.lazy(value => {
                return is_factura('str')
            }),
        }),
        onSubmit: async (values, { setErrors }) => {
            if (!stripe || !elements) {
                return;
            }

            const cardElement = elements.getElement(CardElement);

            // Crear el método de pago en Stripe
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
                billing_details: {
                    name: values.tarjeta_nombre,
                },
            });

            if (error) {
                setErrors({ tarjeta: `Error al generar el método de pago: ${error.message}` });
            } else {
                // Enviar el método de pago al backend
                const newItem = { ...item, bancarios: values, token: paymentMethod.id };
                dispatch(update_usuario(newItem));
                setStep(4);
            }
        },
    });

    useEffect(() => {
        const getCatalogos = async () => {
            const response = await getEntidades();
            setBancarias(response.entidadesBancarias);
            setFinancieras(response.entidadesFinancieras);
        };
        getCatalogos();
    }, []);


    const adminDireccion = (e) => {
        if (e.target.checked) {
            validation.setFieldValue('calle', item.calle)
            validation.setFieldValue('cp', item.cp)
            validation.setFieldValue('cp_id', item.cp_id)
            validation.setFieldValue('numeroExterior', item.numeroExterior)
            validation.setFieldValue('numeroInterior', item.numeroInterior)
            validation.setFieldValue('pais', item.pais)
            validation.setFieldValue('estado', item.estado)
            validation.setFieldValue('municipio', item.municipio)
            validation.setFieldValue('colonia', item.colonia)
            validation.setFieldValue('razon_social', item.nombre)
            validation.setFieldValue('email', item.email)
        }
    }

    return (
        <>
            <SaltarPaso
                title={'Registro de Información Bancaria y de Facturación'}
                text={'Los datos registrados permitirán el cobro y facturación de servicios automáticamente.'}
                step={3}
                setStep={setStep}
                button={false}
            />
            <form onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
            }}>
                <div className="flex flex-wrap max-w-full px-4 mt-10">
                    <div className="w-full text-base font-bold leading-6 mb-2">Método principal de Pago</div>
                    <div className="w-full px-2 mb-3 border-t border-black border-solid"></div>

                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <label>Nombre en la tarjeta</label>
                        <div className="flex flex-col w-full py-1">
                            <input
                                type="text"
                                name="tarjeta_nombre"
                                placeholder="Nombre"
                                value={validation.values.tarjeta_nombre || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                className="w-full py-1.5 px-2.5 rounded border focus:outline-none focus:border-o2o-aqua-hover bg-stone-100 text-ellipsis" // Puedes ajustar esto según tus estilos
                            />
                            {validation.errors?.tarjeta_nombre &&

                                <span className="text-red-600">
                                    {validation.errors?.tarjeta_nombre}
                                </span>
                            }
                        </div>
                    </div>

                    {/* Componente de Stripe para la Tarjeta */}
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <label>Información de la Tarjeta</label>
                        <CardElement className="w-full py-1.5 px-2.5 rounded border focus:outline-none focus:border-o2o-aqua-hover bg-stone-100 text-ellipsis" />
                        {validation.errors?.tarjeta &&

                            <span className="text-red-600">
                                {validation.errors?.tarjeta}
                            </span>
                        }
                    </div>

                    {/* Selección de entidad bancaria */}
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <label>Entidad Bancaria</label>
                        <CustomSelect
                            name="entidad_id"
                            options={[{ label: 'Seleccione una opción', value: 0 }, ...bancarias.map(data => ({ value: data.id, label: data.nombre }))]}
                            value={validation.values.entidad_id || ''}
                            error={validation.errors.entidad_id}
                            onChange={validation.handleChange}
                        />
                    </div>

                    {/* Selección de entidad financiera */}
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <label>Entidad Financiera</label>
                        <CustomSelect
                            name="entidad_financiera"
                            options={[{ label: 'Seleccione una opción', value: 0 }, ...financieras.map(data => ({ value: data.id, label: data.nombre }))]}
                            value={validation.values.entidad_financiera || ''}
                            error={validation.errors.entidad_financiera}
                            onChange={validation.handleChange}
                        />
                    </div>
                </div>

                <Facturacion validation={validation} adminDireccion={adminDireccion} />

                <div className="flex gap-3 text-2xl font-bold text-black max-md:flex-wrap mt-10">
                    <button className="grow justify-center items-center px-16 py-5 shadow-sm bg-stone-100 rounded-[36px] w-fit max-md:px-5 max-md:max-w-full hover:bg-o2o-gris-hover" onClick={() => setModal(true)}>Cancelar registro</button>
                    <button className="grow justify-center px-24 py-5 bg-o2o-aqua shadow-sm rounded-[36px] w-fit max-md:px-8 max-md:max-w-full hover:bg-o2o-aqua-hover" type="submit">Guardar información y continuar</button>
                </div>
            </form>
            <CancelarModal modal={modal} setModal={setModal} />
        </>
    );
};

// Envolver el componente `Cobro` en `Elements` para que funcione Stripe
const WrappedCobro = (props) => (
    <Elements stripe={stripePromise}>
        <Cobro {...props} />
    </Elements>
);

export default WrappedCobro;
