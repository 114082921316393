import React from "react";
import CustomTabsPerfiles from "../../components/CustomTabsPerfiles";
import UnderConstruction from "../UnderConstruction";
import Rewards from "./Rewards";
import ServiciosFacturar from "./ServiciosFacturar";
import InformacionFacturacion from "./InformacionFacturacion";


const Honorarios = () => {
    
    const tabshonorarios = [
        { id: 1, label: 'Servicios por facturar',content: <ServiciosFacturar/> },
        { id: 2, label: 'One 2 One rewards', content: <Rewards/> },
        { id: 3, label: 'Informacion de facturación', content: <InformacionFacturacion/>},
    ];

    return (
        <>
            <div className="flex flex-wrap max-w-full lg:max-w-[75%] mx-auto px-4">
                <div className="p-4">
                    <b className="block mb-2 text-sm font-bold">Esta es tu central de información.</b>
                    <p className="text-sm">Aquí puedes consultar y editar tus datos para conectar con especialistas en enfermería.</p>
                </div>
            </div>
            <CustomTabsPerfiles defaultTab={1} tabs={tabshonorarios} />
        </>
    )
}

export default Honorarios;