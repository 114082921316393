import React, { useEffect, useState } from "react";
import { imgUrl } from "../../../helpers/recursosurl";
import * as Yup from "yup";
import { useFormik } from "formik";
import { UseSelector, useDispatch, useSelector } from "react-redux";
import CalendarizadoBusquedas from "../../../components/registro/administrador/CalendarizadoBusquedas";
import { useNavigate, useParams } from "react-router-dom";
import { pacientesResource, enfermeroResource } from "../../../helpers/configRoutes/backend_helper";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import SinResultados from "./SinResultados";
import BarraBusqueda from "./BarraBusqueda";
import Resultados from "./Resultados";
import { set_resultados,back_resultados } from "../../../store/busquedas/action";

const BuscarEnfermero = () => {
    const resultadoRdx = useSelector(state => state.Busquedas);
    const config = useSelector((state)=>state.configZendesk.zendeskConfig);
    const back = useSelector(state => state.Busquedas.back_resultados);
    const paciente_id_rdx = useSelector(state => state.Busquedas.paciente_id);
    const dispatch = useDispatch()
    const [item, setItem] = useState(null)
    const [enfermeros, setEnfermeros] = useState([])
    const { id } = useParams()
    const [vista, setVista] = useState('busqueda');
    const [filters, setFilters] = useState({perPage:6, page:1, busqueda: true, paciente_id: id, stars: null, sexo: '', rangoEdad: 1 });
    const [meta, setMeta] = useState({ total: 0, current_page: 0 })
    const navigate = useNavigate();

    useEffect(() => {
        const getDataPaciente = async () => {
            if (id) {
                const response = await pacientesResource('show', {}, id)
                if (response.status) {
                    setItem(response.paciente)
                }
            }
        }
        getDataPaciente()
    }, []);

    const paciente = useSelector(state => state.Usuario.usuario)
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: item,
        validationSchema: Yup.object({
            turno: Yup.string().required('Campo requerido'),
            fecha_inicio: Yup.date().required('Campo requerido'),
            fecha_fin: Yup.date()
                .required('Campo requerido')
                .test('fecha-mayor', 'La fecha de término debe ser posterior a la fecha de inicio', function (value) {
                    const fechaInicio = this.parent.fecha_inicio;
                    return fechaInicio < value;
                }),
            dias: Yup.array().of(Yup.number()).required('Campo requerido'),

        }),
        onSubmit: async (values, { setErrors }) => {
        },
    });

    const search = async (pacienteSave = true) => {
        if (pacienteSave) {
            const response = await pacientesResource('put', validation.values, validation.values.id);
            if (response.status) {
                const searchResponse = await enfermeroResource('get', { params: filters });
                if (searchResponse.status) {
                    setEnfermeros(searchResponse.enfermeros)
                    setMeta(searchResponse.meta)
                    dispatch(set_resultados({enfermeros:searchResponse.enfermeros,paciente_id:filters.paciente_id}))
                    setVista('resultados')
                } else {
                    setVista('sinResultados')
                }
            }
        } else {
            const searchResponse = await enfermeroResource('get', { params: filters });
            if (searchResponse.status) {
                setVista('resultados')
                setEnfermeros(searchResponse.enfermeros)
                setMeta(searchResponse.meta)
            } else {
                setVista('sinResultados')
            }
        }

    }

    useEffect(() => {
        const getDataEnfermeros = async () => {
            search(false)
        }
        if(vista=='resultados')
        getDataEnfermeros()
    }, [filters]);

    useEffect(()=>{
        const getSearch =()=>{
            setFilters({...filters,paciente_id:paciente_id_rdx})
            dispatch(back_resultados(false))
            search(false)
        }
        if(back && paciente_id_rdx){
            getSearch(false)
        }
    },[])

    return (
        <>
            {vista == 'busqueda' &&
                <>
                    <div className="flex flex-col items-center max-w-[100%]">
                        <div className="flex flex-wrap max-w-[100%]">
                            <div className="flex flex-col pt-6">
                                <div className="pl-4 md:pl-5 mt-6 w-full rounded-none max-w-[80%]">
                                    <div className="flex flex-col md:flex-row gap-5">
                                        <div className="flex flex-col w-full md:w-[41%]">
                                            <div className="flex flex-col self-stretch my-auto text-black">
                                                <>
                                                    <div className="text-3xl font-bold leading-9">
                                                        <h1>El enfermero ideal está a unos clicks de distancia</h1>
                                                    </div>
                                                    <div className="mt-1.5 text-base leading-6">
                                                        Usa el buscador y nuestra sofisticada plataforma te presentará al enfermero que tú o tu paciente necesitan.
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                        <div className="flex flex-col mt-5 md:mt-0 md:ml-3 w-full md:w-[59%]">
                                            <img
                                                loading="lazy"
                                                src={`${imgUrl}/buscar1.png`}
                                                className="self-stretch my-auto w-full aspect-[1.85]"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex mt-6 md:mt-0 ml-4 md:ml-14 mr-4 md:mr-14 gap-5 pr-5 text-2xl font-bold rounded-xl shadow-sm bg-stone-100 max-w-[80%]">
                                    <div className="justify-center p-5 whitespace-nowrap bg-violet-700 rounded-xl text-stone-100">
                                        1
                                    </div>
                                    <div className="flex-1 justify-center my-auto leading-8">
                                        <h3 className="max-w-[490px]">Elige la(s) fecha(s), turno y horario de servicio</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CalendarizadoBusquedas validation={validation} />
                        <div className="flex flex-col items-center max-w-[100%] mt-11 text-center">
                            <CustomButtonOval
                                type={'success'}
                                onClick={() => search()}
                                className='bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover w-full md:w-[95%] h-16'
                            >
                                <div className="flex justify-center items-center space-x-4">
                                    <img
                                        loading="lazy"
                                        src={`${imgUrl}/lupa.png`}
                                        alt="Lupa"
                                        className="w-4 h-4"
                                    />
                                    <h3>Solicita un enfermero</h3>
                                </div>
                            </CustomButtonOval>
                        </div>
                        <div className="flex flex-col items-center max-w-[100%] mt-11 text-center">
                            <h2 className="text-center">Somos una comunidad segura</h2>
                            <p className="text-base mt-3 mb-5">Consulta nuestros protocolos de seguridad para ti y nuestros profesionistas</p>
                            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                                <CustomButtonOval
                                    type={'success'}
                                    onClick={()=>window.open(config.terminos_condiciones?.url, "_blank")}
                                    className='bg-o2o-gris-arena-button text-sm hover:bg-o2o-aqua-hover'
                                >
                                    Conoce los Términos y condiciones
                                </CustomButtonOval>
                                <CustomButtonOval
                                    type={'success'}
                                    onClick={()=>window.open(config.codigo_conducta?.url, "_blank")}
                                    className='bg-o2o-gris-arena-button text-sm hover:bg-o2o-aqua-hover'
                                >
                                    Lee nuestro Código de conducta
                                </CustomButtonOval>
                            </div>
                        </div>
                    </div>

                </>
            }
            <div className="pl-8 p-4 mt-[50px] w-full rounded-none max-md:pl-5 max-w-[96%] mr-[60px]">
                <div className="flex flex-col mt-10 lg:flex-row">
                    {vista === 'sinResultados' && (
                        <>
                            <BarraBusqueda search={search} paciente={item} setItem={setItem} setFilters={setFilters} filters={filters} />
                            <SinResultados search={search} paciente={item} />
                        </>
                    )}
                    {vista === 'resultados' && (
                        <>
                            <BarraBusqueda search={search} paciente={item} setFilters={setFilters} filters={filters} />
                            <Resultados search={search} paciente={item} enfermeros={enfermeros} setFilters={setFilters} filters={filters} meta={meta}/>
                            
                        </>
                    )}
                </div>
            </div>

        </>
    );
}

export default BuscarEnfermero;