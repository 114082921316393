import React, { useEffect, useState } from "react";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import CustomSelect from "../../../components/inputs/CustomSelect";
import { pacientesResource } from "../../../helpers/configRoutes/backend_helper";
import { update_loader } from "../../../store/loading/action";
import { useDispatch } from "react-redux";

const BarraBusquedaPublico = ({ paciente, setFilters, filters, search }) => {
    const [pacientes, setPacientes] = useState([]);
    const dispatch = useDispatch()
    const rangoEdad = [
        { id: 1, value: '18-100' },
        { id: 2, value: '25-35' },
        { id: 3, value: '36-45' },
        { id: 4, value: '46-55' }
    ];

    const sexos = [
        { id: 1, value: 'Hombre' },
        { id: 2, value: 'Mujer' },
    ];

    return (
        <>
            <div className="w-full md:w-1/4 bg-white p-4 border-r border-gray-200">
                <div className="mb-4">
                    <label htmlFor="search" className="block text-sm font-medium text-gray-700">Otro paciente / Nueva búsqueda</label>
                    <CustomSelect
                        name="pacientes"
                        options={[
                            { label: 'Todos', value: 0 },
                            ...pacientes?.map(tipo => {
                                return { value: tipo?.id, label: tipo?.nombre }
                            })
                        ]}
                        value={''}
                        onChange={(e)=>console.log('oks')}
                        disabled={true}
                    />
                </div>
                <div className="mb-4">
                    <h4 className="text-md font-semibold mb-2">Filtros de búsqueda</h4>
                    <div className="mb-4">
                        <label htmlFor="age-range" className="block text-sm font-medium text-gray-700">Rango de edad</label>
                        <CustomSelect
                            name="rangoEdad"
                            options={[
                                { label: 'Ninguno', value: 0 },
                                ...rangoEdad?.map(tipo => {
                                    return { value: tipo?.id, label: tipo?.value }
                                })
                            ]}
                            value={''}
                            onChange={(e)=>console.log('oks')}
                            disabled={true}
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="gender" className="block text-sm font-medium text-gray-700">Sexo</label>
                        <CustomSelect
                            name="sexos"
                            options={[
                                { label: 'Todos', value: 0 },
                                ...sexos?.map(tipo => {
                                    return { value: tipo?.id, label: tipo?.value }
                                })
                            ]}
                            value={''}
                            onChange={(e)=>console.log('oks')}
                            disabled={true}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Puntuación</label>
                        <div className="flex mt-1 p-3 rounded-[6px] bg-o2o-gris-arena">
                            <svg className={`w-11 h-4 ${'text-o2o-secondary-mostaza'} ms-1`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                            </svg>
                            <svg className={`w-11 h-4 ${'text-o2o-secondary-mostaza'} ms-1`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                            </svg>
                            <svg className={`w-11 h-4 ${'text-o2o-secondary-mostaza'} ms-1`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                            </svg>
                            <svg className={`w-11 h-4 ${'text-o2o-secondary-mostaza'} ms-1`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                            </svg>
                            <svg className={`w-11 h-4 ${'text-o2o-secondary-mostaza'} ms-1`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                            </svg>
                            {/* Resto de los SVG para la puntuación */}
                        </div>
                    </div>
                    <CustomButtonOval
                        type={'success'}
                        isDisabled={true}
                        className='bg-o2o-gris-arena-button text-sm hover:bg-o2o-aqua-hover'
                    >
                        Aplicar filtros
                    </CustomButtonOval>
                </div>
            </div>

        </>
    )
}

export default BarraBusquedaPublico;