import React, { useState, useEffect } from "react";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import DeclinarServicio from "./DeclinarServicio";
import { accion_solicitud } from "../../../helpers/configRoutes/backend_helper";
import ConfirmacionServicio from "./ConfirmacionServicio";
import { useSelector } from "react-redux";

const AceptarServicio = ({ servicio, setComponent,setFilters,filters }) => {
    const config = useSelector((state)=>state.configZendesk.zendeskConfig);
    console.log(config)
    const [loader, setLoader] = useState({loading:false,textCarga:'Generando servicio...'})
    const navigate = useNavigate();
    const aceptar_servicio = async() => {
        setLoader({...loader,loading:true})
        let data = {
            accion:1,
            accionText:'Aceptar',
            servicio_id:servicio.id
        };
        const response =  await accion_solicitud(data)
        if(response.status){
            setFilters(filters)
            setLoader({...loader,loading:false})
            setComponent(<ConfirmacionServicio servicio={response.servicio} setComponent={setComponent}/>)
        }
        setLoader({...loader,loading:false})
    }

    return (
        <>
            <div className="flex flex-col items-start mt-[46px] mx-auto lg:ml-[19%] lg:max-w-[75%] px-4">
    <CustomButtonOval 
        className="flex items-center bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover mb-[20px]" 
        onClick={() => setComponent(false)}
    >
        <svg
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-2"
        >
            <path
                d="M5.80078 1L1.00078 5L5.80078 9"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
        Regresar a solicitudes de servicio
    </CustomButtonOval>

    <div className="border-0 rounded-lg p-4 mb-4 w-full lg:w-[547px]">
        <div className="text-[32px] mb-[18px] font-semibold">
            <h2>Aceptar el servicio de {servicio.administrador.nombre} {servicio.administrador.apellidos} para {servicio.paciente.nombre} {servicio.paciente.apellidos}</h2>
        </div>
        <div className="text-base">
            <p>¿Deseas ofrecer los cuidados enfermeros para este paciente?</p>
            <p className="mt-[20px]">
                Al aceptar la solicitud de servicio se hacen válidos los <span onClick={() => window.open(config.terminos_condiciones?.url, "_blank")} className="text-blue-700 cursor-pointer hover:underline">Términos y Condiciones</span> descritos en el contrato con One2One Nurses; incluyendo tus derechos y obligaciones como proveedor de servicios.
            </p>
            <p className="mt-[20px]">
                Recuerda que durante el servicio se deben respetar los puntos descritos en el <span onClick={() => window.open(config.codigo_conducta?.url, "_blank")} className="text-blue-700 cursor-pointer hover:underline">Código de Conducta</span>; 
                 por lo tanto, es indispensable conocer y comprender cada uno de ellos. Una vez aceptado el servicio, la cancelación o incumplimiento es acreedora a una sanción.
            </p>
        </div>
        <div className="flex justify-start space-x-2 mt-[30px]">
            <CustomButtonOval onClick={() => window.open(config.terminos_condiciones?.url, "_blank")} className="flex justify-end bg-o2o-gris-arena-button text-sm hover:bg-gris-arena-hover">
                Leer los Términos y Condiciones
            </CustomButtonOval>
            <CustomButtonOval onClick={() => window.open(config.codigo_conducta?.url, "_blank")} className="flex justify-end bg-o2o-gris-arena-button text-sm hover:bg-gris-arena-hover">
                Leer el Código de Conducta
            </CustomButtonOval>
        </div>
    </div>

    <div className="border rounded-lg p-4 mb-4 bg-o2o-gris-calido-hover w-full lg:w-[925px]">
        <div className="flex justify-end space-x-2">
            <CustomButtonOval loading={loader.loading} textCarga={loader.textCarga} onClick={() => aceptar_servicio()} className="flex justify-end bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover">
                Si, aceptar el servicio
            </CustomButtonOval>
            <CustomButtonOval isDisabled={loader.loading} onClick={() => setComponent(<DeclinarServicio servicio={servicio} setComponent={setComponent}/>)} className="flex justify-end bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover">
                No, declinar el servicio
            </CustomButtonOval>
        </div>
    </div>
</div>

        </>
    );
}

export default AceptarServicio;