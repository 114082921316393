import { useEffect, useState } from 'react';
import CustomCalendar from './CustomCalendar';
import { useFormik } from 'formik';
import * as Yup from "yup";
import CalendarizadoBusquedas from './registro/administrador/CalendarizadoBusquedas';
import { getEnfermerosPublico, getEspecialidades, getTiempos } from '../helpers/configRoutes/backend_helper';
import Card from './Card';
import { showFormErrors } from '../helpers/showErrors';
import { warningToast } from '../helpers/Toast';
import CustomButtonSquare from './buttons/CustomButtonSquare';
import { useDispatch, useSelector } from 'react-redux';
import { set_resultados } from '../store/busquedasPublicas/action';
import { useNavigate } from 'react-router-dom';

const SearchForm = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { c_turnos, c_horarios, catEspecialidades } = useSelector((state) => state.configPublic);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showTurno, setShowTurno] = useState(false);

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
        if (showBuscador) {
            setShowBuscador(!showBuscador);
        }
        if (showTurno) {
            setShowTurno(!showTurno);
        }
    };

    const toggleBuscador = () => {
        setShowBuscador(!showBuscador);
        if (showCalendar) {
            setShowCalendar(!showCalendar);
        }
        if (showTurno) {
            setShowTurno(!showTurno);
        }
    };
    const toggleTurno = () => {
        setShowTurno(!showTurno);
        if (showCalendar) {
            setShowCalendar(!showCalendar);
        }
        if (showBuscador) {
            setShowBuscador(!showBuscador);
        }
    };
    const oneDayBefore = new Date();
    oneDayBefore.setDate(oneDayBefore.getDate() - 1);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            especialidad_id: '',
            turno_id: '',
            tiempo_id: '',
            fecha_inicio: '',
            fecha_fin: '',
            dias: [],
        },
        validationSchema: Yup.object({
            especialidad_id: Yup.number().required('Campo requerido'),
            turno_id: Yup.number().required('Campo requerido'),
            tiempo_id: Yup.number().required('Campo requerido'),
            fecha_inicio: Yup.date().required('Campo requerido').min(oneDayBefore, 'La fecha debe ser actual o futura'),
            fecha_fin: Yup.date()
                .required('Campo requerido')
                .test('fecha-mayor', 'La fecha de término debe ser posterior a la fecha de inicio', function (value) {
                    const fechaInicio = this.parent.fecha_inicio;
                    return fechaInicio < value;
                }),
            dias: Yup.array().of(Yup.number()).required('Campo requerido'),

        }),
        onSubmit: async (values, { setErrors }) => {
            const response = await getEnfermerosPublico(values)
            if(response.status){
                dispatch(set_resultados(response.enfermeros))
                navigate('/busqueda-resultados')
            }
        },
    });
    const [showBuscador, setShowBuscador] = useState(false)
    return (
        <div className='flex justify-center item-center w-auto'>
            <div className='absolute top-2 flex items-start justify-start pt-5 pl-2 w-full md:w-[750px]'>
                <p className='text-sm'>Esta es una búsqueda rápida:</p>
            </div>

            <div className='flex absolute top-16 items-center justify-center w-full md:w-[750px] h-[48px]'>
                <div className="flex justify-between bg-white rounded-full w-full h-full">
                    <span onClick={toggleBuscador} className="flex items-center justify-center md:justify-normal text-gray-800 font-bold bg-o2o-aqua-shearch rounded-full min-w-12 md:w-[258px]">
                        <span className="md:px-4">
                            <img src='/icons/lupa.svg' alt='icon' className='w-[13px]' />
                        </span>
                        <p className='hidden md:inline-block font-bold'>{validation.values?.especialidad?.nombre ?? 'Buscar un enfermero: '}</p>
                    </span>
                    <input
                        type="text"
                        placeholder="Elige la(s) Fecha(s):"
                        className="rounded py-1 px-3  mr-2 w-1/4 placeholder:text-black"
                        onClick={toggleCalendar}
                    />
                    <input onClick={toggleTurno} type="text" placeholder="Turno" className="rounded py-1 px-3 mr-2  w-1/4 placeholder:text-black" />
                    <form onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        showFormErrors(validation);
                        return false;
                    }}
                    >
                        <button type='submit' className="bg-o2o-aqua-ir right-0 text-black font-bold rounded-full w-[48px] h-[48px]  hover:bg-o2o-aqua-hover">Ir</button>
                    </form>
                </div>
            </div>
            {showCalendar && (
                <>
                    <div className="absolute z-20 w-full md:w-[750px]  bg-white top-32 rounded-lg  shadow-lg">
                        <CustomCalendar
                            fechaInicio={validation.values?.fecha_inicio}
                            fechaFin={validation.values?.fecha_fin}
                            setFechaInicio={(e) => console.log(e, 'sdokkd')}
                            setFechaFin={(e) => console.log(e, 'riemp final')}
                            setDiasSeleccionados={(e) => console.log(e, 'dias')}
                            diasSeleccionados={validation.values?.dias}
                            validation={validation}
                        />
                    </div>
                </>
            )}

            <div className='flex justify-center item-center w-auto'>
                <div className='absolute items-center justify-center pt-3 top-28 hidden lg:block w-full md:w-[750px]'>
                    {showTurno && (
                        <>
                            <Card
                                title={<>
                                    <span className='text-gray-400'>Turno y tiempo </span>
                                    <span>
                                        {
                                            c_horarios.filter(t => t.id === validation.values?.tiempo_id)[0]
                                                ? `${c_horarios.filter(t => t.id === validation.values?.tiempo_id)[0].nombre}`
                                                : 'Turno no encontrado'
                                        }
                                    </span>
                                    <span>
                                        | {
                                            c_turnos.filter(t => t.id === validation.values?.turno_id)[0]
                                                ? `${c_turnos.filter(t => t.id === validation.values?.turno_id)[0].nombre} [${c_turnos.filter(t => t.id === validation.values?.turno_id)[0].hora_inicio} a ${c_turnos.filter(t => t.id === validation.values?.turno_id)[0].hora_fin}]`
                                                : 'Turno no encontrado'
                                        }
                                    </span>
                                    <div className="border-t border-gray-300 mt-2"></div>
                                </>}
                                content={
                                    <>
                                        <div className='w-[750px] mx-auto'>
                                            <div className="w-full lg:w-[98%] max-w-[100%] rounded-xl p-3">
                                                <div className="text-base font-bold leading-4 text-black mb-3">



                                                </div>
                                                {/* Contenedor de columnas */}
                                                <div className="flex flex-col lg:flex-row gap-6">
                                                    {/* Columna de Horarios */}
                                                    <div className="w-full lg:w-1/2">
                                                        <div className="text-base font-bold leading-4 text-black mb-2">
                                                            Turno
                                                        </div>
                                                        <div className='w-full mb-3 lg:mb-0'>
                                                            {c_horarios?.map((data, index) => (
                                                                <CustomButtonSquare
                                                                    type='button'
                                                                    key={index}
                                                                    className={`self-start text-xs hover:bg-o2o-gris-arena ${validation.values?.tiempo_id == data?.id ? 'bg-o2o-gris-hover' : ''}`}
                                                                    onClick={() => validation.setFieldValue('tiempo_id', data.id)}
                                                                >
                                                                    {data.nombre}
                                                                </CustomButtonSquare>
                                                            ))}
                                                        </div>
                                                    </div>

                                                    {/* Columna de Turnos */}
                                                    <div className="w-full lg:w-1/2">
                                                        <div className="text-base font-bold leading-4 text-black mb-2">
                                                            Horario
                                                        </div>
                                                        <div className='w-full mb-3 lg:mb-0'>
                                                            {c_turnos?.map((data, index) => (
                                                                <CustomButtonSquare
                                                                    type='button'
                                                                    key={index}
                                                                    className={`self-start text-xs hover:bg-o2o-gris-arena ${validation.values?.turno_id == data?.id ? 'bg-o2o-gris-hover' : ''}`}
                                                                    onClick={() => validation.setFieldValue('turno_id', data.id)}
                                                                >
                                                                    {data.nombre}
                                                                </CustomButtonSquare>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Descripción */}
                                                <p className="text-xs mt-3 mb-2">
                                                    {
                                                        c_turnos.filter(t => t.id === validation.values?.turno_id)[0]
                                                            ? `El turno ${c_turnos.filter(t => t.id === validation.values?.turno_id)[0].nombre} comprende un horario de ${c_turnos.filter(t => t.id === validation.values?.turno_id)[0].hora_inicio} a ${c_turnos.filter(t => t.id === validation.values?.turno_id)[0].hora_fin}`
                                                            : 'Turno no encontrado'
                                                    }
                                                </p>

                                            </div>
                                        </div>

                                    </>
                                }
                                tags={[]}
                            />
                        </>
                    )}
                </div>
            </div>
            <div className='flex justify-center item-center w-auto'>
                <div className='absolute items-center justify-center pt-3 top-28 hidden lg:block w-full md:w-[750px]'>
                    {showBuscador &&
                        <Card
                            title={<>
                                <span className='text-gray-400'>Con especialidad en</span>
                                <div className="border-t border-gray-300 mt-2"></div>
                            </>}
                            content={
                                <>
                                    <div className='w-[750px] mx-auto'>
                                        <div className='flex'>
                                            <div className='lg:w-[60%] w-full'>
                                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 overflow-x-auto">
                                                    {catEspecialidades.map((item, index) => (
                                                        <button
                                                            key={index}
                                                            className={`hover:text-o2o-morado hover:font-bold text-start text-menuBusqueda ${validation.values?.especialidad_id === item.id ? 'text-o2o-morado font-bold' : ''}`}
                                                            index={index}
                                                            onClick={() => [validation.setFieldValue('especialidad_id', item.id), validation.setFieldValue('especialidad', item)]}
                                                        >
                                                            {item.nombre}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className='lg:w-[30%] w-full ml-[18px]'>
                                                <h3 className='text-black'>{validation.values?.especialidad?.nombre}</h3>
                                                <p className='text-sm'>{validation.values?.especialidad?.descripcion}</p>
                                            </div>
                                            <div className='lg:w-[10%] w-full ml-[18px]'>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            tags={[]}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default SearchForm;

